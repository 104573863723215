import React, { useState } from 'react';
import { ReactElementResize } from 'react-element-resize';

import Button from '../../../../components/Button';
import { ReactComponent as TrashIcon } from '../../../../assets/images/trash.svg';
import { ReactComponent as CalculatorIcon } from '../../../../assets/images/calculator.svg';
import { Calculator } from '../../../../reducers/calculators';

const MAX_FONT_SIZE = 40;
const RESULT_LEFT_PADDING = 50;

interface Props {
  calculator: Calculator;
  id: number;
  getNumberDisplay: (value: number | string) => string;
  showDeleteButton: boolean;
  removeCalculator: () => void;
}

export const CalculatorResult = ({
  calculator: { history, value },
  id,
  getNumberDisplay,
  showDeleteButton,
  removeCalculator
}: Props) => {
  const displayValue = getNumberDisplay(value);
  const [elementWidth, setElementWidth] = useState<number>(0);

  function getHistory() {
    return history.reduce((prev: string, item: any) => `${prev} ${getNumberDisplay(item.value)} ${item.operation}`, '');
  }

  function getResultFontSize() {
    const availableSpace = elementWidth - RESULT_LEFT_PADDING;
    let fontSize = MAX_FONT_SIZE;

    while ((fontSize * .58) * displayValue.length > availableSpace) {
      fontSize--;
    }

    return fontSize;
  }

  const fontSize = getResultFontSize();

  return (
    <ReactElementResize onResize={({ width }: { width: number }) => setElementWidth(width)}>
      {() => (
        <div className="calculator__result">
          <div className="calculator__result__actions">
            <div className="calculator-id">
              <CalculatorIcon />&nbsp;{id}
            </div>
            {showDeleteButton && (
              <div className="delete-calculator">
                <Button onClick={removeCalculator} >
                  <TrashIcon />
                </Button>
              </div>
            )}
          </div>
          <div className="calculator__result__last-operation">
            {getHistory()}
          </div>
          <div className="calculator__result__value" style={{ fontSize }}>
            {displayValue}
          </div>
        </div>
      )}
    </ReactElementResize>
  )
};

export default CalculatorResult;