import React from 'react';

import './Header.scss';

const Header = () => {
  return (
    <div className="header-container">
      <div className="header-container__title">
        Calculator Batch
      </div>
    </div>
  );
};

export default Header;