import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { createStore } from 'redux';

import history from './modules/history';
import reducers from './reducers'
import CalculatorList from './modules/CalculatorList/CalculatorList';
import './App.scss';

function App() {
  const store = createStore(reducers);

  return (
    <Provider store={store}>
      <Router history={history}>
        <div className="App">
          <Route exact path="/" component={CalculatorList} />
        </div>
      </Router >
    </Provider>
  );
}

export default App;
