import React from 'react';
import { useDispatch } from 'react-redux';

import { addCalculator } from '../../actions';
import Button from '../Button';
import { ReactComponent as PlusIcon } from '../../assets/images/plus-sign.svg';
import { ReactComponent as CalculatorIcon } from '../..//assets/images/calculator.svg';

interface Props {
  kind?: 'secondary' | 'primary';
}

const AddCalculatorButton = ({ kind = 'primary' }: Props) => {
  const dispatch = useDispatch();

  return (
    <Button kind={kind} onClick={() => dispatch(addCalculator())}>
      <PlusIcon className="button__icon button__icon--small" />
      <CalculatorIcon className="button__icon" />
    </Button>
  );
};

export default AddCalculatorButton;