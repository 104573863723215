import React from 'react';
import classnames from 'classnames';

import './SessionOperations.scss';
import { SessionOperation } from '../../../../../reducers/calculators';

interface Props {
  className?: string;
  operations: SessionOperation[];
  getNumberDisplay: (value: string | number) => string;
  copyResult: (value: string | number) => void;
}

const SessionOperations = ({ className = '', operations, getNumberDisplay, copyResult }: Props) => {
  function getOperations() {
    return operations.filter((operation, index) => !index || !operation.first);
  }

  const calculatorOperations = getOperations();

  const classes = classnames('calculator-session', className);

  return (
    <div className={classes}>
      {calculatorOperations
        .map((operation, index) => {
          const previousOperation = calculatorOperations[index - 1];

          if (!previousOperation) {
            return <div />;
          }

          const key = `${index}-${previousOperation.result}${operation.operation}${operation.value}=${operation.result}`;

          return (
            <div className="history-operation" key={key}>
              <div className="operation-index">{index}</div>
              <div className="operation-number" onClick={() => copyResult(previousOperation.result)}>{getNumberDisplay(previousOperation.result)}</div>
              <div>{operation.operation}</div>
              <div className="operation-number" onClick={() => copyResult(operation.value)}>{getNumberDisplay(operation.value)}</div>
              <div>=</div>
              <div className="operation-number" onClick={() => copyResult(operation.result)}>{getNumberDisplay(operation.result)}</div>
            </div>
          );
        })}
    </div>
  )
};

export default SessionOperations;