import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-awesome-modal';

import Button from '../../../../components/Button';
import { ReactComponent as ReturnIcon } from '../../../../assets/images/return.svg';
import { ReactComponent as CalculatorIcon } from '../../../../assets/images/calculator.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/arrow-down.svg';

import './CalculatorResultShareDialog.scss';

interface Props {
  visible: boolean;
  calculatorIndex: string;
  closeModal: () => void;
  getNumberDisplay: (value: number | string) => string;
  sendResult: (id: string) => void;
}

const CalculatorResultShareDialog = ({ visible, calculatorIndex, sendResult, closeModal, getNumberDisplay }: Props) => {
  const calculators = useSelector<any, any>(store => store.calculators);
  const options = Object.keys(calculators)
    .filter(index => index.toString() !== calculatorIndex.toString());
  const calculator = calculators[calculatorIndex];

  function renderCalculators() {
    return options.map(option => (
      <button
        key={option}
        tabIndex={0}
        className="calculator-item"
        onClick={() => {
          sendResult(option);
          closeModal();
        }}>
        <CalculatorIcon />{+option + 1} <input disabled value={getNumberDisplay(calculators[option].value)} />
      </button>
    ));
  }

  return (
    <Modal visible={visible} effect="fadeInUp" onClickAway={closeModal}>
      <div className="result-share-dialog">
        <div className="calculator-item">
          <CalculatorIcon />{+calculatorIndex + 1} <input disabled value={getNumberDisplay(calculator.result)} />
        </div>
        <div className="arrow-down">
          <ArrowDownIcon />
        </div>
        <div className="calculators-list">
          {renderCalculators()}
        </div>
        <div className="result-share-dialog__actions">
          <Button kind="secondary" onClick={closeModal}>
            <ReturnIcon className="button__icon--small" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CalculatorResultShareDialog;