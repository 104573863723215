import { createAction } from 'redux-actions';

export const ADD_CALCULATOR = 'ADD_CALCULATOR';
export const SEND_RESULT = 'SEND_RESULT';
export const SET_VALUE = 'SET_VALUE';
export const REMOVE_CALCULATOR = 'REMOVE_CALCULATOR';
export const TOGGLE_CALCULATOR_HISTORY = 'TOGGLE_CALCULATOR_HISTORY';
export const REMOVE_NUMBER = 'REMOVE_NUMBER';
export const ADD_NUMBER = 'ADD_NUMBER';
export const CLEAR_CALCULATOR = 'CLEAR_CALCULATOR';
export const APPLY_OPERATION = 'APPLY_OPERATION';
export const FINISH_OPERATION = 'FINISH_OPERATION';

export const addCalculator = createAction(ADD_CALCULATOR);
export const sendResult = createAction(SEND_RESULT, (sourceCalculatorIndex: string | number, destinationCalculatorIndex: string | number) => ({ sourceCalculatorIndex, destinationCalculatorIndex }));
export const setValue = createAction(SET_VALUE, (calculatorIndex: string | number, value: string | number) => ({ calculatorIndex, value }));
export const removeCalculator = createAction(REMOVE_CALCULATOR, (calculatorIndex: string | number) => ({ calculatorIndex }));
export const toggleCalculatorHistory = createAction(TOGGLE_CALCULATOR_HISTORY, (calculatorIndex: string | number) => ({ calculatorIndex }));
export const removeNumber = createAction(REMOVE_NUMBER, (calculatorIndex: string | number) => ({ calculatorIndex }));
export const addNumber = createAction(ADD_NUMBER, (calculatorIndex: string | number, number: string | number) => ({ calculatorIndex, number }));
export const clearCalculator = createAction(CLEAR_CALCULATOR, (calculatorIndex: string | number) => ({ calculatorIndex }));
export const applyOperation = createAction(APPLY_OPERATION, (calculatorIndex: string | number, operation: string) => ({ calculatorIndex, operation }));
export const finishOperation = createAction(FINISH_OPERATION, (calculatorIndex: string | number) => ({ calculatorIndex }));
