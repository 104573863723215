import React, { useEffect, useRef, useLayoutEffect } from 'react';

import { OPERATORS, Calculator as ICalculator } from '../../../reducers/calculators';
import CalculatorButton from './CalculatorButton';
import { ReactComponent as CalculatorIcon } from '../../../assets/images/calculator.svg';
import { ReactComponent as BackspaceIcon } from '../../../assets/images/backspace.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right-arrow.svg';
import { ReactComponent as HistoryIcon } from '../../../assets/images/history.svg';
import CalculatorResultShareDialog from './CalculatorResultShareDialog';
import CalculatorResult from './CalculatorResult';
import CalculatorHistory from './CalculatorHistory';
import history from '../../history';

import './Calculator.scss';
import { getNumberDisplay } from '../../../common/utils';

interface Props {
  index: string;
  calculator: ICalculator;
  applyOperation: (operation: string) => void;
  finishOperation: () => void;
  clearCalculator: () => void;
  removeNumber: () => void;
  addNumber: (number: number | string) => void;
  removeCalculator: () => void;
  toggleHistory: () => void;
  addCalculator: () => void;
  showDeleteButton: boolean;
  showResultShareDialog: boolean;
  sendResult: (optionId: string) => void;
}

const Calculator = ({
  index,
  calculator,
  applyOperation,
  finishOperation,
  clearCalculator,
  addNumber,
  removeNumber,
  removeCalculator,
  showDeleteButton,
  showResultShareDialog,
  toggleHistory,
  addCalculator,
  sendResult
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const id = +index + 1;

  function selectCalculator() {
    if (!showDeleteButton) {
      return;
    }

    history.push(`?calculator=${index}`);
  }

  function clearSelectedCalculator() {
    history.push('/');
  }

  function focusCalculator() {
    containerRef.current?.focus();
  }

  useLayoutEffect(focusCalculator, []);

  useEffect(() => {
    function handleUserKeyPress(event: KeyboardEvent) {
      console.log(event);
      const { key } = event;

      switch (key) {
        case '+':
        case '-':
          return applyOperation(key);
        case '/':
          return applyOperation('÷');
        case '*':
          return applyOperation('×');
        case 'Enter':
          return finishOperation();
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '0':
          return addNumber(+key);
        case 'Backspace':
          return removeNumber();
        case ',':
        case '.':
          return addNumber('.');
        case 'h':
        case 'H':
          return toggleHistory();
        case 'm':
        case 'M':
        case 'c':
        case 'C':
          return selectCalculator();
        case 'n':
        case 'N':
          return addCalculator();
        default:
          return;
      }
    };

    const containerRefElement = containerRef.current;

    containerRefElement?.addEventListener('keydown', handleUserKeyPress);

    return () => {
      containerRefElement?.removeEventListener('keydown', handleUserKeyPress);
    }
  });

  return (
    <div className="calculator" tabIndex={0} ref={containerRef}>
      <CalculatorResultShareDialog
        visible={showResultShareDialog}
        closeModal={clearSelectedCalculator}
        calculatorIndex={index}
        getNumberDisplay={getNumberDisplay}
        sendResult={(optionId: string) => {
          sendResult(optionId);
          focusCalculator();
        }}
      />
      <CalculatorResult
        removeCalculator={removeCalculator}
        calculator={calculator}
        id={id}
        getNumberDisplay={getNumberDisplay}
        showDeleteButton={showDeleteButton}
      />
      <div className="calculator__actions" onClick={focusCalculator}>
        {calculator.historyOpen && (
          <CalculatorHistory
            calculatorIndex={index}
            getNumberDisplay={getNumberDisplay}
            calculator={calculator}
            toggleHistory={toggleHistory}
          />
        )}
        <div className="column">
          <CalculatorButton kind="dark" onClick={clearCalculator}>C</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(7)}>7</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(4)}>4</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(1)}>1</CalculatorButton>
          <CalculatorButton onClick={toggleHistory}>
            <HistoryIcon className="calculator-icon" />
          </CalculatorButton>
        </div>
        <div className="column">
          <CalculatorButton kind="dark" onClick={() => applyOperation(OPERATORS.DIVISION)}>&divide;</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(8)}>8</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(5)}>5</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(2)}>2</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(0)}>0</CalculatorButton>
        </div>
        <div className="column">
          <CalculatorButton kind="dark" onClick={() => applyOperation(OPERATORS.MULTIPLICATION)}>&times;</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(9)}>9</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(6)}>6</CalculatorButton>
          <CalculatorButton onClick={() => addNumber(3)}>3</CalculatorButton>
          <CalculatorButton onClick={() => addNumber('.')}>,</CalculatorButton>
        </div>
        <div className="column">
          <CalculatorButton kind="dark" onClick={selectCalculator}>
            {showDeleteButton && (
              <div className="share-result">
                <CalculatorIcon />
                {id}
                <RightArrowIcon />
                <CalculatorIcon />
              </div>
            )}
          </CalculatorButton>
          <CalculatorButton kind="dark" onClick={() => removeNumber()}>
            <BackspaceIcon className="calculator-icon" />
          </CalculatorButton>
          <CalculatorButton kind="dark" onClick={() => applyOperation(OPERATORS.SUBSTRACTION)}>-</CalculatorButton>
          <CalculatorButton kind="dark" onClick={() => applyOperation(OPERATORS.SUM)}>+</CalculatorButton>
          <CalculatorButton kind="primary" onClick={finishOperation}>=</CalculatorButton>
        </div>
      </div>
    </div>
  );
};

export default Calculator;