import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { setValue } from '../../../../actions';
import Button from '../../../../components/Button';
import { ReactComponent as HistoryIcon } from '../../../../assets/images/history.svg';
import SessionOperations from './SessionOperations';
import { Calculator } from '../../../../reducers/calculators';

import './CalculatorHistory.scss';

interface Props {
  calculatorIndex: string;
  getNumberDisplay: (value: string | number) => string;
  calculator: Calculator;
  toggleHistory: () => void;
}

export const CalculatorHistory = ({ calculatorIndex, getNumberDisplay, calculator: { sessions, sessionOperations }, toggleHistory }: Props) => {
  const dispatch = useDispatch();

  function copyResult(value: string | number) {
    dispatch(setValue(+calculatorIndex, value));
  }

  const classes = classnames('calculator-history', {
    'first-row': +calculatorIndex % 2 === 0,
    'second-row': +calculatorIndex % 2 !== 0
  });

  return (
    <div className={classes}>
      <div className="calculator-history__actions">
        <HistoryIcon />
        <Button onClick={toggleHistory}>X</Button>
      </div>
      <div className="calculator-history__operations">
        {sessions.map((session: any, index: any) => (
          <SessionOperations
            className="calculator-previous-session"
            key={index}
            operations={session}
            getNumberDisplay={getNumberDisplay}
            copyResult={copyResult}
          />
        ))}
        <SessionOperations
          operations={sessionOperations}
          getNumberDisplay={getNumberDisplay}
          copyResult={copyResult}
        />
      </div>
    </div>
  )
};

export default CalculatorHistory;