import React from 'react';
import classNames from 'classnames';

import './Button.scss';

interface Props {
  onClick: () => void;
  kind?: 'secondary' | 'primary';
  children: React.ReactNode;
}

const BUTTON_KINDS = {
  primary: 'button--primary',
  secondary: 'button--secondary'
};

const Button = ({ onClick, kind = 'primary', children }: Props) => {
  const classes = classNames('button', BUTTON_KINDS[kind]);

  return <button className={classes} onClick={onClick}>{children}</button>;
};

export default Button;