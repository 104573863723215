import React from 'react';

import AddCalculatorButton from '../../../components/AddCalculatorButton';
import './CalculatorPlaceholder.scss';

const CalculatorPlaceholder = () => {
  return (
    <div className="calculator calculator-placeholder">
      <AddCalculatorButton />
    </div>
  );
};

export default CalculatorPlaceholder;