function thousandsSeparators(number: number | string) {
  const numberParts = number.toString().split(".");
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numberParts.join(".");
}

export function getNumberDisplay(number: number | string) {
  if (isNaN(+number)) {
    return 'Not valid';
  }

  const value = number.toString();

  if (!value) {
    return '';
  }

  const [firstHalf, secondHalf] = value.split('.');
  const whole = thousandsSeparators(firstHalf);

  let decimals = '';

  if (secondHalf || value.includes('.')) {
    decimals = `,${secondHalf}`;
  }

  return `${whole}${decimals}`;
}