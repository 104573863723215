import { handleActions } from 'redux-actions';

import {
  REMOVE_CALCULATOR,
  FINISH_OPERATION,
  CLEAR_CALCULATOR,
  ADD_NUMBER,
  REMOVE_NUMBER,
  TOGGLE_CALCULATOR_HISTORY,
  SET_VALUE,
  SEND_RESULT,
  ADD_CALCULATOR,
  APPLY_OPERATION
} from '../actions';

const DEFAULT_CALCULATOR = {
  history: [], // { value: number, result: number, operation: string }
  result: 0,
  value: 0,
  resetValue: true,
  lastOperation: '',
  historyOpen: false,
  sessionOperations: [],
  sessions: []
};

export const OPERATORS = {
  SUM: '+',
  SUBSTRACTION: '-',
  DIVISION: '÷',
  MULTIPLICATION: '×',
  EQUALS: '='
};

export const defaultState = {
  0: DEFAULT_CALCULATOR
};

export interface Operation {
  value: number | string,
  result: number | string,
  operation: string;
}

export interface SessionOperation extends Operation {
  first?: boolean;
}

export interface Calculator {
  result: string | number,
  value: string | number,
  resetValue: boolean,
  historyOpen: boolean,
  sessionOperations: SessionOperation[],
  sessions: SessionOperation[][];
  history: Operation[];
}

export interface Calculators {
  [key: string]: Calculator
}

const reducer = handleActions({
  [ADD_CALCULATOR]: (calculators: Calculators) => ({ ...calculators, [Object.keys(calculators).length]: DEFAULT_CALCULATOR }),
  [SEND_RESULT]: (calculators: Calculators, {
    payload: { sourceCalculatorIndex, destinationCalculatorIndex }
  }: any) => {
    const newCalculators = { ...calculators };

    newCalculators[destinationCalculatorIndex] = {
      ...newCalculators[destinationCalculatorIndex],
      value: parseFloat(newCalculators[sourceCalculatorIndex].result.toString())
    };

    return newCalculators;
  },
  [SET_VALUE]: (calculators: Calculators, {
    payload: { calculatorIndex, value }
  }: { payload: { calculatorIndex: string, value: number } }) => {
    const newCalculators = { ...calculators };

    newCalculators[calculatorIndex] = {
      ...newCalculators[calculatorIndex],
      resetValue: false,
      value
    };

    return newCalculators as any;
  },
  [REMOVE_CALCULATOR]: (calculators: Calculators, { payload: { calculatorIndex } }: { payload: { calculatorIndex: string } }) => {
    const newCalculators = { ...calculators };
    delete newCalculators[calculatorIndex];

    return Object.keys(newCalculators).reduce((prev, newCalculatorIndex, index) => {
      return {
        ...prev,
        [index]: newCalculators[newCalculatorIndex]
      }
    }, {});
  },
  [APPLY_OPERATION]: (calculators: Calculators, { payload: { calculatorIndex, operation } }: { payload: any }) => {
    const calculator = calculators[calculatorIndex];

    return {
      ...calculators,
      [calculatorIndex]: registerOperation(calculator, operation)
    };
  },
  [FINISH_OPERATION]: (calculators: Calculators, { payload: { calculatorIndex } }: { payload: { calculatorIndex: string } }) => {
    const calculator = calculators[calculatorIndex];
    const lastOperation = calculator.history[calculator.history.length - 1];

    if (!calculator.history.length || (lastOperation && lastOperation.operation === OPERATORS.EQUALS)) {
      return calculators;
    }

    const result = performOperation(calculator.result, calculator.value, lastOperation.operation);
    const sessionOperations = [
      {
        value: calculator.result,
        result,
        operation: lastOperation.operation
      },
      {
        value: calculator.value,
        result,
        operation: OPERATORS.EQUALS
      }
    ];

    return {
      ...calculators,
      [calculatorIndex]: {
        ...calculator,
        result: result,
        value: result,
        resetValue: false,
        history: [...sessionOperations],
        sessionOperations: [
          ...calculator.sessionOperations, {
            value: calculator.value,
            result,
            operation: lastOperation.operation
          }
        ]
      }
    };
  },
  [CLEAR_CALCULATOR]: (calculators: Calculators, { payload: { calculatorIndex } }: { payload: { calculatorIndex: string } }) => {
    const calculator = calculators[calculatorIndex];
    const sessions = [...calculators[calculatorIndex].sessions];

    if (calculator.sessionOperations.length) {
      sessions.push(calculator.sessionOperations);
    }

    return {
      ...calculators,
      [calculatorIndex]: {
        ...DEFAULT_CALCULATOR,
        historyOpen: calculators[calculatorIndex].historyOpen,
        sessionOperations: [],
        sessions
      }
    };
  },
  [ADD_NUMBER]: (calculators: Calculators, { payload: { calculatorIndex, number } }: { payload: any }) => {
    const calculator = calculators[calculatorIndex];
    const lastOperation = calculator.history[calculator.history.length - 1] || {};
    let value = calculator.resetValue
      ? ''
      : calculator.value;
    if ((number.toString() === '.' && value.toString().includes('.')) || value.toString().length >= 16) {
      return calculators;
    }

    if (value.toString() === '0' && number.toString() === '.') {
      value = 0;
    }

    if (+value === 0 && !/(\d+)\.(\d+)?/.test(value.toString())) {
      value = '';
    }

    let base;

    if (lastOperation.operation !== OPERATORS.EQUALS) {
      base = value;
    } else if (number.toString() === '.' && lastOperation.operation === OPERATORS.EQUALS) {
      base = '0';
    } else {
      base = '';
    }

    return {
      ...calculators,
      [calculatorIndex]: {
        ...calculators[calculatorIndex],
        value: `${base}${number}`,
        resetValue: false,
        history: lastOperation.operation === OPERATORS.EQUALS
          ? []
          : calculator.history
      }
    };
  },
  [REMOVE_NUMBER]: (calculators: Calculators, { payload: { calculatorIndex } }: { payload: { calculatorIndex: string } }) => {
    const calculator = calculators[calculatorIndex];
    const lastOperation = calculator.history[calculator.history.length - 1] || {};

    if (lastOperation.operation === OPERATORS.EQUALS) {
      return calculators;
    }

    let value: number | string = calculator.value;
    value = value.toString().substring(0, value.toString().length - 1);

    if (!value) {
      value = 0;
    }


    return {
      ...calculators,
      [calculatorIndex]: {
        ...calculators[calculatorIndex],
        value
      }
    };
  },
  [TOGGLE_CALCULATOR_HISTORY]: (calculators: Calculators, { payload: { calculatorIndex } }: { payload: { calculatorIndex: string } }) => {
    const calculator = calculators[calculatorIndex];

    return {
      ...calculators,
      [calculatorIndex]: {
        ...calculators[calculatorIndex],
        historyOpen: !calculator.historyOpen
      }
    };
  },
}, defaultState);

const doesHistoryIncludeOperator = (history: Operation[]) => {
  return history.some(item => [
    OPERATORS.SUM,
    OPERATORS.SUBSTRACTION,
    OPERATORS.DIVISION,
    OPERATORS.MULTIPLICATION
  ].includes(item.operation))
};

const registerOperation = (calculator: Calculator, operation: string) => {
  const history = calculator.history;
  const includesOperator = doesHistoryIncludeOperator(history);

  if (calculator.resetValue && includesOperator) {
    history[history.length - 1].operation = operation;

    return { ...calculator, history };
  }

  const lastOperation = history[history.length - 1];

  if (!lastOperation) {
    const newOperation = {
      value: calculator.value,
      result: calculator.value,
      operation,
      first: true
    };

    return {
      ...calculator,
      resetValue: true,
      result: calculator.value,
      history: [newOperation],
      sessionOperations: [...calculator.sessionOperations, newOperation]
    };
  }

  if (lastOperation.operation === OPERATORS.EQUALS) {
    const newOperation = {
      value: calculator.result,
      result: lastOperation.result,
      operation,
      first: true
    };

    return {
      ...calculator,
      resetValue: true,
      history: [newOperation],
      sessionOperations: [...calculator.sessionOperations, newOperation]
    };
  }

  const result = performOperation(calculator.result, calculator.value, operation);
  const newOperation = {
    value: calculator.value,
    result,
    operation
  };

  return {
    ...calculator,
    resetValue: true,
    value: result,
    result,
    history: [...history, newOperation],
    sessionOperations: [...calculator.sessionOperations, newOperation]
  };
};

const performOperation = (result: string | number, value: string | number, operation: string) => {
  switch (operation) {
    case OPERATORS.SUM:
      return parseFloat(result.toString()) + parseFloat(value.toString());
    case OPERATORS.SUBSTRACTION:
      return parseFloat(result.toString()) - parseFloat(value.toString());
    case OPERATORS.DIVISION:
      return parseFloat(result.toString()) / parseFloat(value.toString());
    case OPERATORS.MULTIPLICATION:
      return parseFloat(result.toString()) * parseFloat(value.toString());
    default:
      return parseFloat(result.toString());
  }
};

export default reducer;
