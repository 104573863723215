import React from 'react';
import classNames from 'classnames';

import './CalculatorButton.scss';

interface Props {
  onClick: () => void;
  kind?: 'light' | 'dark' | 'primary';
  children: React.ReactNode;
}

const BUTTON_KINDS = {
  dark: 'calculator-button--dark',
  light: 'calculator-button--light',
  primary: 'calculator-button--primary'
};

const CalculatorButton = ({ onClick, kind = 'light', children }: Props) => {
  const classes = classNames('calculator-button', BUTTON_KINDS[kind]);

  return <button className={classes} onClick={onClick}>{children}</button>;
};

export default CalculatorButton;