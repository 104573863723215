import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  addCalculator,
  finishOperation,
  clearCalculator,
  addNumber,
  removeNumber,
  applyOperation,
  removeCalculator,
  toggleCalculatorHistory,
  sendResult
} from '../../actions'
import Calculator from './Calculator';
import Header from './Header';
import CalculatorPlaceholder from './CalculatorPlaceholder';
import history from '../history';
import { Calculators } from './../../reducers/calculators';

import './CalculatorList.scss';

const CalculatorList = () => {
  const calculators: Calculators = useSelector<any, any>(store => store.calculators);
  const dispatch = useDispatch();
  const search = queryString.parse(useLocation().search);
  const selectedCalculatorIndex = search.calculator;

  useEffect(() => {
    history.replace('/');
  }, [calculators]);

  return (
    <div className="calculator-page">
      <div className="calculator-page__header">
        <Header />
      </div>
      <div className="calculator-page__list">
        {Object.entries(calculators).map(([index, calculator]) => (
          <Calculator
            key={index}
            index={index}
            showResultShareDialog={selectedCalculatorIndex === index}
            showDeleteButton={Object.entries(calculators).length > 1}
            calculator={calculator}
            removeCalculator={() => dispatch(removeCalculator(index))}
            removeNumber={() => dispatch(removeNumber(index))}
            addNumber={(number: number | string) => dispatch(addNumber(index, number))}
            clearCalculator={() => dispatch(clearCalculator(index))}
            applyOperation={(operation: string) => dispatch(applyOperation(index, operation))}
            finishOperation={() => dispatch(finishOperation(index))}
            toggleHistory={() => dispatch(toggleCalculatorHistory(index))}
            addCalculator={() => dispatch(addCalculator())}
            sendResult={(optionId: string) => dispatch(sendResult(index, optionId))}
          />
        ))}
        <CalculatorPlaceholder />
      </div>
    </div>
  );
};

export default CalculatorList;